@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

html {
  font-size: 12px;
  @apply sm:text-[12px];
  @apply md:text-[13px];
  @apply lg:text-[14px];
  @apply xl:text-[15px];
  @apply 2xl:text-[16px];
}
